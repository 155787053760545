<template>
    <v-dialog
        :value="value"
        scrollable
        width="700"
        @input="onInput(false)"
        @keydown.esc="onInput(false)"
        @click:outside="onInput(false)"
    >
        <media-table
            v-model="selectedItems"
            height="700"
            :payload="payload"
            hide-author
            hide-size
            selectable
            :selectableTypes="selectableTypes"
            searchable
        >
            <template #footer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="onCancel"
                >
                    {{ $trans('Cancel') }}
                </v-btn>
                <v-btn
                    :disabled="selectedItems.length === 0"
                    color="primary"
                    text
                    @click="onSelect"
                >
                    {{ $trans('Add') }}
                </v-btn>
            </template>
        </media-table>
    </v-dialog>
</template>

<script>
export default {
    name: 'MediaPicker',
    components: {
        MediaTable: () => import('@apps/media/components/MediaTable')
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: 'Select documents'
        },
        payload: {
            type: Object,
            default: function () {
                return {}
            }
        },
        selectableTypes: {
            type: Array,
            default: null
        }
    },
    data() {
        return {
            mediaTableKey: 0,
            selectedItems: []
        }
    },
    methods: {
        onInput(value) {
            this.$emit('input', value)
        },
        onCancel() {
            this.selectedItems = []
            this.$emit('input', false)
        },
        onSelect() {
            this.$emit('select', [].concat(this.selectedItems))
            this.selectedItems = []
            this.$emit('input', false)
        }
    }
}
</script>

<style lang=scss>

</style>
